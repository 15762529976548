import mnMN from 'antd/lib/locale/mn_MN';
import mnMsg from "../locales/mn_MN_school.json";
// import mnMsg from "../locales/mn_MN.json";

const MnLang = {
    antd: mnMN,
    locale: 'mn',
    messages: {
        ...mnMsg
    },
};
export default MnLang;
