import { gql } from "@apollo/client";

export const ME = gql`
  query me {
    me {
      pk
      isStaff
      isStudent
      isTeacher
      email
      firstName
      lastName
      student {
        studentCode
        surname
        familyName
        familyNameMgl
        name
        nameMgl
        religion
        registerNo
        nationality
        state
        photo
        phone
        phone2
        address
        addressLive
        citizen
        degree {
          id
          name
        }
        activity {
          id
          name
        }
        joinDate
        joinBefore
        sex
        birthdate
        classtime {
          id
          name
        }
        birthCity {
          id
          name
        }
        birthDistrict {
          id
          name
        }
        status {
          id
          name
        }
        statusExtra {
          id
          name
        }
        school {
          id
          name
        }
        classes {
          id
          classes
        }
        section {
          id
          section
        }
        program {
          id
          program
        }
        joinSchoolyear {
          id
          schoolyear
        }
      }
      teacher {
        teacherCode
        surname
        familyName
        name
        religion
        registerNo
        photo
        phone
        phone2
        address
        addressLive
        citizen
        degree {
          name
        }
        joinDate
        joinBefore
        sex
        birthdate
        birthCity {
          name
        }
        birthDistrict {
          name
        }
        status {
          name
        }
        school {
          name
        }
        subSchool {
          name
        }
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation passwordChange(
    $oldPassword: String!
    $newPassword1: String!
    $newPassword2: String!
  ) {
    passwordChange(
      oldPassword: $oldPassword
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      errors
      token
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation changePassword($id: Int!, $password: String!) {
    changePassword(id: $id, password: $password) {
      changed {
        status
      }
    }
  }
`;

export const MY_PROFILE = gql`
  query accountSelf {
    accountSelf {
      email
      familyName
      name
      phone
      phone2
      address
      addressLive
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateAccount(
    $address: String
    $addressLive: String
    $email: String
    $familyName: String
    $name: String
    $phone: String
    $phone2: String
  ) {
    updateAccount(
      address: $address
      addressLive: $addressLive
      email: $email
      familyName: $familyName
      name: $name
      phone: $phone
      phone2: $phone2
    ) {
      account {
        name
      }
    }
  }
`;

export const USER_GROUP = gql`
  query accountById($id: Int!) {
    accountById(id: $id) {
      id
      groups {
        id
      }
    }
  }
`;
