import { ReactComponent as Svg549478501579605851 } from 'assets/svg/549478501579605851.svg'; 
import { ReactComponent as Svg716726921582967213 } from 'assets/svg/716726921582967213.svg';
import { ReactComponent as Svg10098485941582960210 } from 'assets/svg/10098485941582960210.svg';
import { ReactComponent as Svg10567788931582960203 } from 'assets/svg/10567788931582960203.svg';
import { ReactComponent as Svg10736121931582955601 } from 'assets/svg/10736121931582955601.svg';
import { ReactComponent as Svg12524023391582967227 } from 'assets/svg/12524023391582967227.svg';
import { ReactComponent as Svg13154706311582967215 } from 'assets/svg/13154706311582967215.svg';
import { ReactComponent as Svg13714930121582967229 } from 'assets/svg/13714930121582967229.svg';
import { ReactComponent as Svg14233754871582967215 } from 'assets/svg/14233754871582967215.svg';
import { ReactComponent as Svg14267696681582985685 } from 'assets/svg/14267696681582985685.svg';
import { ReactComponent as Svg14370526081579605843 } from 'assets/svg/14370526081579605843.svg';
import { ReactComponent as Svg16065603701582967209 } from 'assets/svg/16065603701582967209.svg';
import { ReactComponent as Svg16869399861582967234 } from 'assets/svg/16869399861582967234.svg';
import { ReactComponent as Svg18278341941582955603 } from 'assets/svg/18278341941582955603.svg';
import { ReactComponent as Svg19238795471582884291 } from 'assets/svg/19238795471582884291.svg';
import { ReactComponent as Svg19468929971595156179 } from 'assets/svg/19468929971595156179.svg';
import { ReactComponent as Svg20097037261582967227 } from 'assets/svg/20097037261582967227.svg';
import { ReactComponent as Svg20263121251582967230 } from 'assets/svg/20263121251582967230.svg';
import { ReactComponent as Svg2385229281579605846 } from 'assets/svg/2385229281579605846.svg';
import { ReactComponent as Svg2796120701610058221 } from 'assets/svg/2796120701610058221.svg';
import { ReactComponent as Svg3012977901582967232 } from 'assets/svg/3012977901582967232.svg';
import { ReactComponent as Svg4485990371582967234 } from 'assets/svg/4485990371582967234.svg';
import { ReactComponent as Svg6410916891582967216 } from 'assets/svg/6410916891582967216.svg';
import { ReactComponent as Svg6895864091582985684 } from 'assets/svg/6895864091582985684.svg';
import { ReactComponent as Svg8229382321600677163 } from 'assets/svg/8229382321600677163.svg';
import { ReactComponent as Svg8389786041582967211 } from 'assets/svg/8389786041582967211.svg';
import { ReactComponent as Svg9709949121586786415 } from 'assets/svg/9709949121586786415.svg';

export const svg = [
  { name: '549478501579605851', svg: Svg549478501579605851 },
  { name: '716726921582967213', svg: Svg716726921582967213 },
  { name: '10098485941582960210', svg: Svg10098485941582960210 },
  { name: '10567788931582960203', svg: Svg10567788931582960203 },
  { name: '10736121931582955601', svg: Svg10736121931582955601 },
  { name: '12524023391582967227', svg: Svg12524023391582967227 },
  { name: '13154706311582967215', svg: Svg13154706311582967215 },
  { name: '13714930121582967229', svg: Svg13714930121582967229 },
  { name: '14233754871582967215', svg: Svg14233754871582967215 },
  { name: '14267696681582985685', svg: Svg14267696681582985685 },
  { name: '14370526081579605843', svg: Svg14370526081579605843 },
  { name: '16065603701582967209', svg: Svg16065603701582967209 },
  { name: '16869399861582967234', svg: Svg16869399861582967234 },
  { name: '18278341941582955603', svg: Svg18278341941582955603 },
  { name: '19238795471582884291', svg: Svg19238795471582884291 },
  { name: '19468929971595156179', svg: Svg19468929971595156179 },
  { name: '20097037261582967227', svg: Svg20097037261582967227 },
  { name: '20263121251582967230', svg: Svg20263121251582967230 },
  { name: '2385229281579605846', svg: Svg2385229281579605846 },
  { name: '2796120701610058221', svg: Svg2796120701610058221 },
  { name: '3012977901582967232', svg: Svg3012977901582967232 },
  { name: '4485990371582967234', svg: Svg4485990371582967234 },
  { name: '6410916891582967216', svg: Svg6410916891582967216 },
  { name: '6895864091582985684', svg: Svg6895864091582985684 },
  { name: '8229382321600677163', svg: Svg8229382321600677163 },
  { name: '8389786041582967211', svg: Svg8389786041582967211 },
  { name: '9709949121586786415', svg: Svg9709949121586786415 },
]