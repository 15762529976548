import React, { useContext, useState } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {
    EditOutlined,
    SettingOutlined,
    LogoutOutlined,
    UserOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOutSuccess } from 'redux/actions/Auth';
import { Link } from "react-router-dom";
import IntlMessage from "components/util-components/IntlMessage";
import { UserContext } from "hooks/UserContextProvider";
import { BASE_SERVER_URL } from "configs/AppConfig";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { useApolloClient } from '@apollo/client';

const menuItem = [
    {
        title: <IntlMessage id="main.profile" />,
        icon: EditOutlined,
        path: "/app/update-profile"
    },
    {
        title: <IntlMessage id="main.update_password" />,
        icon: SettingOutlined,
        path: "/app/update-password"
    },
]

export const NavProfile = ({ signOutSuccess }) => {

    const contextData = useContext(UserContext)
    const [user] = useState(contextData.user);
    const client = useApolloClient();

    function logout() {
        localStorage.removeItem(AUTH_TOKEN);
        client.clearStore();
        signOutSuccess(user);
    }
    const profileMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header">
                <div className="d-flex">
                    {(() => {
                        if (user.isStudent) {
                            return (
                                <Avatar
                                    src={BASE_SERVER_URL + user.student.photo}
                                />
                            )
                        } else if (user.isTeacher) {
                            return (
                                <Avatar
                                    src={BASE_SERVER_URL + user.teacher.photo}
                                />
                            )
                        } else {
                            return (
                                <Avatar
                                    style={{ backgroundColor: '#87d068' }}
                                    icon={<UserOutlined />}
                                />
                            )
                        }
                    })()}
                    <div className="pl-3">
                        <h4 className="mb-0">
                            {user.lastName} {user.firstName}
                        </h4>
                        <span className="text-muted">
                            {(() => {
                                if (user.isStudent) {
                                    return (
                                        <IntlMessage id="role.student" />
                                    )
                                } else if (user.isTeacher) {
                                    return (
                                        <IntlMessage id="role.teacher" />
                                    )
                                } else {
                                    return (
                                        <IntlMessage id="role.staff" />
                                    )
                                }
                            })()}
                        </span>
                    </div>
                </div>
            </div>
            <div className="nav-profile-body">
                <Menu>
                    {menuItem.map((el, i) => {
                        return (
                            <Menu.Item key={i}>
                                <Link to={el.path}>
                                    <Icon className="mr-3" type={el.icon} />
                                    <span className="font-weight-normal">{el.title}</span>
                                </Link>
                            </Menu.Item>
                        );
                    })}
                    <Menu.Item key={menuItem.length + 1} onClick={logout}>
                        <span>
                            <LogoutOutlined className="mr-3" />
                            <span className="font-weight-normal">{<IntlMessage id="main.logout" />}</span>
                        </span>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );

    return (
        <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
            <Menu className="d-flex align-item-center" mode="horizontal">
                <Menu.Item key="profile">
                    {(() => {
                        if (user.isStudent) {
                            return (
                                <Avatar
                                    src={BASE_SERVER_URL + user.student.photo}
                                />
                            )
                        } else if (user.isTeacher) {
                            return (
                                <Avatar
                                    src={BASE_SERVER_URL + user.teacher.photo}
                                />
                            )
                        } else {
                            return (
                                <Avatar
                                    style={{ backgroundColor: '#87d068' }}
                                    icon={<UserOutlined />}
                                />
                            )
                        }
                    })()}
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
}

export default connect(null, { signOutSuccess })(NavProfile)
