import React from 'react'
import { Switch, Route, } from "react-router-dom";
import ReportViews from 'views/report-views';
import Loading from 'components/shared-components/Loading';
import { useThemeSwitcher } from "react-css-theme-switcher";

export const ReportLayout = () => {
	const { status } = useThemeSwitcher();

    if (status === 'loading') {
        return <Loading cover="page" />;
    }

	return (
        <Switch>
            <Route path="" component={ReportViews} />
        </Switch>
	)
}


export default ReportLayout
