import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import { ApolloProvider } from '@apollo/client';
import { client } from 'configs/ApolloConfig';
import { ConfigProvider } from 'antd';


ReactDOM.render(
    <ConfigProvider>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider> 
    </ConfigProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
